#policy-affiliate, #info-affiliate{
    ul{
        margin: 0px;
        padding: 0px;
        li{
            list-style: none;
            padding: 5px 0px;
            padding-left: 10px;
            svg{
                color: $primary;
                margin-right: 5px;
            }
        }
    }
}