.app-logo {
    height: $logo-height;
    width: $logo-width;
    background: url('~assets/utils/images/logo-inverse.png');
  }
  
  .app-logo-inverse {
    height: $logo-height;
    width: $logo-width;
    background: url('~assets/utils/images/logo.png');
  }

  .app-logo-text{
    font-size: 30px;
    color: #fff;
  }
  
  .app-login-box {
    .app-logo {
      margin-bottom: $layout-spacer-lg;
    }
  
    h4 {
      margin-bottom: $layout-spacer-x;
      font-weight: normal;
  
      div {
        opacity: .6;
      }
  
      span {
        font-size: $font-size-lg;
      }
    }
  }