.ant-form-item-explain-error{
    > div{
        margin: 5px 0px 8px;
    }
}
.app-container{
    .ant-result{
        div{
            color: #1c2d41!important;
        }
    }
}

tr.ant-table-placeholder td{
    border-bottom: 0px;
}

.form-antd-h {
    .ant-form-item-label{
        text-align: left;
        width: 100%;
    }
}
.ant-modal-confirm-btns{
    display: flex;
}