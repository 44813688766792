.ant-message-error{
    display: flex;
    align-items: center;
}
.ant-form-item-label > label{
    font-size: 13.5px;
    // font-weight: 500!important;
}
.ant-btn[disabled]{
    // display: flex;
    // align-items: center;
}
.ant-btn-white{
    .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active{
        background: #fff!important;
        color: rgba(0, 0, 0, 0.85);
        border-color: #d9d9d9;
    }
    &.ant-btn[disabled], &.ant-btn[disabled]:hover, &.ant-btn[disabled]:focus, &.ant-btn[disabled]:active{
        background: #fff!important;
        color: rgba(0, 0, 0, 0.85);
        border-color: #d9d9d9;
    }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #033C73 !important;
}
.ant-tabs-ink-bar{
    background-color: #033C73;
}
.ant-message-loading{
    display: flex;
    align-items: center;
}
.ant-table-summary-top .ant-table-summary{
    display: table-header-group;
}

.ant-table-summary-top .ant-table-summary .ant-table-cell{
    background: #fafafa;
}

.antd-select-tree-hide-icon .ant-select-tree-switcher{
    display: none;
}

.ant-pagination{
    .ant-pagination-next, .ant-pagination-prev{
        .ant-pagination-item-link{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.ant-btn .ant-btn-loading-icon{
    // display: flex;
}

.ant-btn-primary{
    border-color: $primary;
    background: $primary;
    &:hover, &:focus{
        border-color: #5f87d8;
        background: #5f87d8;
    }
}

.ant-modal-confirm .ant-modal-confirm-btns{
    justify-content: end;
}
.ant-modal-confirm-body>.anticon svg{
    font-size: 22px;
}
.ant-modal-content{
    border-radius: 4px;
}