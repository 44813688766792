#detail-transaction {
    ul{
        display: flex;
        flex-wrap: wrap;
        padding: 0px;
        margin: 0px;
        li{
            width: 50%;
            list-style: none;
            padding: 5px;
        }
    }
    .card{
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 10px;
        background-color: #f1f4f6;
        .card-body{
            .card-body-main{
                padding-left: 20px!important;
                margin-bottom: 10px;
                margin-top: 10px;
            }
        }
    }
    h4{
        font-size: 20px;
    }
    .border-t{
        border-top: 1px solid #ccc;
        margin-bottom: 10px;
    }
}