#list-packages{
    padding: 0px 20px;
    .card{
        text-align: center;
        padding-top: 50px;
        padding-bottom: 40px;
        cursor: pointer;
        .h2-heading {
            margin-bottom: 3.75rem;
            text-align: center;
        }
        .card-hotpic{
            position: absolute;
            top: 2px;
            right: 5px;
            .hot {
                object-fit: cover;
            }
            img {
                width: 50px;
                height: 50px;
                object-fit: cover;
            }
        }
        .card-discount{
            // position: absolute;
            // top: 6px;
            // left: 2px;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            color: #fff;
            background: #4caf50;
        }
        .card-title {
            margin-bottom: 2rem;
            .decoration-lines {
                width: 30px;
                margin-bottom: 0.25rem;
            }
            &.flipped {
                transform: scaleX(-1);
            }
            span {
                margin-right: 0.75rem;
                margin-left: 0.75rem;
                color: #272556;
                font-weight: 600;
                font-size: 1.75rem;
                line-height: 2rem;
                letter-spacing: -0.2px;
            }
        }
        .card-body{
            .list-unstyled {
                margin-bottom: 3rem;
                li {
                    margin-bottom: 0.625rem;
                    font-size: 14px;
                    &.line-through{
                        text-decoration: line-through;
                    }
                }
            }
            .price {
                // margin-bottom: 3rem;
                color: #272556;
                font-weight: 600;
                font-size: 38px;
                line-height: 2rem;
                letter-spacing: -0.2px;
                span {
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 0;
                }
            }
            del{
                font-size: 15px;
                margin-right: 6px;
            }
        }
    }
}
