
// Header Buttons

.header-btn-lg {
  padding: 0 0 0 1.5rem;
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    position: relative;

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background: $gray-600;
  }
  &::before{
    position: absolute;
    left: -1px;
    top: 50%;
    background: #dee2e6;
    width: 1px;
    height: 30px;
    margin-top: -15px;
    content: "";
  }

}